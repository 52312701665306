import * as React from "react";
import Icon404 from "../assets/svg/Icon404.svg";
import Layout from "../components/Layout/layout";
import Section from "../components/Layout/Section";
import Link from "../components/UI/Link";
import * as errorStyles from "./error.module.css";

const NotFoundPage = () => {
    return (
        <Layout>
            <Section>
                <div className={errorStyles.error}>
                    <Icon404 className={errorStyles.error__code} />
                    <h1 className={errorStyles.error__title}>Упс. Такой страницы не существует.</h1>
                    <p className={errorStyles.error__desc}>
                        Попробуйте вернуться на{" "}
                        <Link to="/" withArrow={false}>
                            главную страницу
                        </Link>{" "}
                        и начать сначала.
                    </p>
                </div>
                <div>
                    <div className={errorStyles.error__gradient} />
                    <div className={errorStyles.error__gradient} />
                    <div className={errorStyles.error__gradient} />
                </div>
            </Section>
        </Layout>
    );
};

export default NotFoundPage;
